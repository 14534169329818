import React from "react"
import { Link, graphql } from "gatsby"

const ClientsList = ({ data: { allDatoCmsClientLanding } }) => {
  return (
    <ul>
      {allDatoCmsClientLanding.nodes.map((item) => (
        <li
          key={item.id}
          style={{
            display: "flex",
            gap: "1rem",
            maxWidth: "fit-content",
            marginBottom: "0.5rem",
            borderBottom: "1px solid",
            minWidth: "30rem",
          }}
        >
          <p style={{ minWidth: "8rem" }}>{item.title}</p>
          <Link
            to={`/${item.slug}`}
          >{`https://harveycameron.nz/${item.slug}`}</Link>
        </li>
      ))}
    </ul>
  )
}

export default ClientsList

export const query = graphql`
  query ClientLandingList {
    allDatoCmsClientLanding {
      nodes {
        id: originalId
        title
        slug
      }
    }
  }
`
